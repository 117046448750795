import React from "react";
import { useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

import { mutaTheme } from "../../utils/mutaTheme";
import "../../utils/mutaStyles.css";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

const drawerWidth = 260;

function PoliciesCard() {
  //Para la barra lateral con desplazamiento dentro del contenido de la misma página

  const definiciones = useRef(null);
  const principios = useRef(null);
  const tratamientosyfinalidades = useRef(null);
  const vigencia = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const policiesTopics = [
    {
      name: "Definiciones",
      scrollToSection: definiciones,
    },
    {
      name: "Principios",
      scrollToSection: principios,
    },
    {
      name: "Tratamientos y finalidades",
      scrollToSection: tratamientosyfinalidades,
    },
    {
      name: "Vigencia",
      scrollToSection: vigencia,
    },
  ];

  return (
    <ThemeProvider theme={mutaTheme}>
      <Drawer
        anchor="right"
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <Typography> &nbsp; Indice: </Typography>
          <List>
            {policiesTopics.map((topic, index) => {
              return (
                <ListItem disablePadding key={index}>
                  <ListItemButton
                    onClick={() => scrollToSection(topic.scrollToSection)}
                  >
                    {topic.name}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Drawer>

      <Card mb={6}>
        <CardContent>
          <Typography color="primary" gutterBottom>
            <span className="mainTitle" style={{ marginLeft: "0" }}>
              Política de Tratamiento de Datos Personales 
            </span>
          </Typography>
          <Typography paragraph>
            La información y los datos personales recolectados por <b>REACEICO S.A.S.</b>, sociedad colombiana, con domicilio en Galapa, Atlántico, ubicada en la dirección Carrera 53 N 3 05, número de teléfono +57 310 536 5618 y correo electrónico legal@mutaworld.com (en adelante “Muta”) en su sitio web https://mutaworld.com/ (en adelante el “Sitio Web”) y en la aplicaciones móviles (en adelante la “App” o la “Aplicación”)  y/o por los diferentes canales de comunicación de Muta, es suministrada por los usuarios de las mismas de forma libre y voluntaria, habiendo leído, entendido y aceptado en su integridad la presente política de tratamiento de datos personales, para que sean administrados por Muta en calidad de responsable del tratamiento o por quien éste designe para el cumplimiento de los deberes legales, lo que implica su recolección; almacenamiento en servidores o repositorios de Muta o de terceros; almacenamiento y custodia de los mismos, circulación, utilización y supresión de los mismos de acuerdo con lo aquí establecido.
          </Typography>

          <Typography ref={definiciones} color="primary" gutterBottom>
            <span className="secondaryTitle">
              Definiciones
            </span>
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Se entenderá por:
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Autorización:</b> Consentimiento previo, expreso e informado del titular de datos personales para llevar a cabo el tratamiento de sus datos personales, la cual puede ser recolectada de manera (i) escrita, (ii) oral o (iii) mediante conductas inequívocas, que permitan concluir de manera razonable que este otorgó la autorización.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Aviso de privacidad:</b> Documento físico, electrónico o en cualquier otro formato generado por el responsable del tratamiento, que se pone a disposición del titular para el tratamiento de sus datos personales. En el aviso de privacidad se comunica al titular la información relativa a la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las características del tratamiento que se pretende dar a los datos personales.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Base de datos:</b> Conjunto organizado de datos personales físico o electrónico (digital) que sea objeto de tratamiento manual o automatizado.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Datos personales:</b> Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables. La naturaleza de los datos personales puede ser pública, semiprivada, privada o sensible.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Dato privado:</b> Es el dato que por su naturaleza íntima o reservada sólo es relevante para el Titular.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Dato público:</b> Es el dato calificado como tal según los mandatos de la ley o de la Constitución Política y aquel que no sea semiprivado, privado o sensible. Son públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio, a su calidad de comerciante o de servidor público y aquellos que puedan obtenerse sin reserva alguna. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales, que no estén sometidos a reserva.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Datos sensibles:</b> Son aquellos que afectan la intimidad del titular de datos personales o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos (huella dactilar, el iris del ojo, voz, forma de caminar, palma de la mano o los rasgos del rostro, fotografías, videos, entre otros). A los datos personales de niños, niñas y/o adolescentes, se les aplicarán las mismas normas y procedimientos que a los datos sensibles, y no se le dará tratamiento alguno que pueda vulnerar o amenazar su desarrollo físico, mental y emocional.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Datos semiprivados:</b> Son aquellos que no tienen una naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no solo a su titular, sino a un grupo de personas o a la sociedad en general. Se entiende por dato semiprivado, entre otros, la información relacionada con seguridad social y con el comportamiento financiero y crediticio.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Derecho de Hábeas Data:</b> Derecho que tienen todas las personas a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bancos de datos y en archivos de entidades públicas y privadas y a solicitar la inclusión o supresión de sus datos personales o revocar la autorización para el tratamiento de los mismos para alguna finalidad.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Encargado del tratamiento:</b> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del responsable del tratamiento.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Protección de datos:</b> Son todas las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Responsable del tratamiento:</b> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Titular o Usuario:</b> Persona natural cuyos datos personales sean objeto de tratamiento.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Transferencia:</b> La transferencia de datos tiene lugar cuando el responsable y/o encargado del tratamiento de datos personales, envía la información o los datos personales a un receptor, que a su vez es responsable del tratamiento y se encuentra dentro o fuera del país.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Transmisión:</b> Tratamiento de datos personales que implica la comunicación de los mismos dentro o fuera del país de domicilio del responsable del tratamiento, cuando tenga por objeto la realización de un tratamiento por el encargado por cuenta del responsable.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Tratamiento:</b> Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, actualización, uso, circulación, transferencia, transmisión o supresión.
                </Typography>
              </li>
            </ul>
          </span>

          <Typography ref={principios} color="primary" gutterBottom>
            <span className="secondaryTitle">
              Principios
            </span>
          </Typography>
          <Typography paragraph>
            Los siguientes, son los principios rectores en materia de protección de datos personales, y aplicarán al tratamiento que realicen el responsable de tratamiento, sus empleados y todas aquellas terceras personas naturales o jurídicas a quienes transmita o transfiera datos personales de los titulares que comprenden sus grupos de interés, cuando estos realicen algún tratamiento sobre los mismos:
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Principio de legalidad:</b> El tratamiento a los datos personales se realizará de acuerdo con los requerimientos legales establecidos en las normas vigentes aplicables.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Principio de finalidad:</b> El tratamiento de los datos personales debe obedecer a una finalidad legítima de acuerdo con la Constitución y la Ley, la cual debe ser informada al Titular.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Principio de libertad:</b> El tratamiento sólo puede llevarse a cabo con el consentimiento, previo, expreso e informado del titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento. Quedan exceptuados de este principio los datos públicos, los cuales podrán ser objeto de tratamiento sin que se requiera autorización del titular, conforme con lo dispuesto por las normas vigentes.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Principio de veracidad o calidad:</b> La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Principio de transparencia:</b> En el tratamiento debe garantizarse el derecho del titular a obtener en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Principio de acceso y circulación restringida:</b> Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los titulares o terceros autorizados.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Principio de seguridad:</b> La información sujeta a tratamiento, se deberá proteger mediante el uso de las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros, evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Principio de confidencialidad:</b> Todas las personas que intervengan en el tratamiento de datos personales están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento.
                </Typography>
              </li>
            </ul>
          </span>

          <Typography ref={tratamientosyfinalidades} color="primary" gutterBottom>
            <span className="secondaryTitle">
              Tratamientos y finalidades
            </span>
          </Typography>
          <Typography paragraph>
            Para los efectos de la presente Política de Privacidad, el responsable del tratamiento directamente o a través de encargados del tratamiento, podrá recolectar, almacenar, usar, circular, actualizar, suprimir o realizar cualquier otro tipo de tratamiento sobre los datos personales de sus grupos de interés, ajustándose en todo momento a lo dispuesto por las normas vigentes y para las finalidades que se describen a continuación.
          </Typography>
          <Typography color="primary" gutterBottom>
            Finalidades generales para el tratamiento de datos personales de todos los grupos de interés
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Identificación de los titulares.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Recabar o recolectar los datos personales e incorporarlos y almacenarlos en nuestra base de datos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Ordenar, catalogar, clasificar, dividir, segmentar o separar la información suministrada.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Verificar, comprobar o validar los datos suministrados.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Realizar transmisión y transferencia nacional e internacional y almacenamiento y custodia de información y/o datos personales en archivos físicos o servidores propios y/o de terceros, ubicados dentro o fuera del país, en países seguros en materia de protección de datos o aquellos que no lo sean, siempre que se requiera para el desarrollo de las actividades propias de la organización y las relaciones con los diferentes grupos de interés.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Conservación de la información con fines históricos, científicos y estadísticos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Garantizar el ejercicio de cualquier derecho de los titulares, sus empleadores o contratantes.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Registro y control de entrada y salida de documentos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Administración de sistemas de información, gestión de claves, administración de usuarios, etc.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Creación y administración de usuarios y contraseñas para el ingreso a los diferentes aplicativos, equipos tecnológicos y de cómputo del Responsable del Tratamiento y cuentas de correo electrónico.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Planeación, control, medición y seguimiento del impacto de las decisiones tomadas al interior de la organización y análisis de impacto de factores externos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Diseño, elaboración e implementación de estrategias y metas para optimizar recursos económicos, tecnológicos y humanos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Envío de comunicaciones relacionadas con las finalidades contenidas en la presente política de privacidad, las actividades propias del responsable del tratamiento, a través de los datos de contacto profesionales, empresariales y/o personales de los titulares, incluyendo, pero sin limitarse a teléfono fijo y/o móvil, correo físico y/o electrónico, mensajes de texto SMS y/o MMS, chats, RDC, medios electrónicos y/o cualquier otro medio de comunicación.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Campañas de actualización de datos del titular, su empleador o contratante.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Controles, estadísticas e históricos de las relaciones mantenidas con los titulares de los diferentes grupos de interés.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Base para toma de decisiones.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Soporte en procesos de auditorías internas y/o externas, revisorías fiscales, consultorías e implementación de planes de mejora.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Cumplimiento de principios, normas y estándares nacionales e internacionales, incluyendo, pero sin limitarse a aquellos requeridos para la certificación Internacional ISCC.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Reportes ante autoridades administrativas y judiciales competentes.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Atención de requerimientos realizados por autoridades administrativas y judiciales competentes.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Elaboración y presentación de demandas y denuncios ante las autoridades competentes, así como ejercer el derecho de defensa en cualquier proceso administrativo y/o judicial.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Cumplimiento a las obligaciones derivadas de los contratos suscritos entre el responsable del tratamiento y los titulares, o con los contratantes o empleadores de estos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión administrativa, financiera y contable, creación de terceros, y registro en las bases de datos del responsable del tratamiento.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión fiscal y generación de información tributaria.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Atención de PQRSDF.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Contratación de pólizas de seguros y solicitud de amparos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Solicitud de créditos o servicios financieros.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Recabar, tener, manejar y utilizar la información para realizar control y prevención del fraude, control y prevención de lavado de activos y financiación del terrorismo.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Compartir los datos personales con terceros para las finalidades comerciales y lucrativas indicadas en este documento, incluyendo el suministro o venta de los mismos a empresas de consumo masivo para su libre utilización, siempre y cuando la misma cumpla con las obligaciones previstas en la ley colombiana o en su país de origen para el tratamiento de datos personales. Los usuarios aceptan expresamente y reconocen que Muta tendrá la libertad de realizar la venta de dichos datos personales a cualquier persona natural o jurídica, con fines de lucro, siempre y cuando se pretenda utilizar para fines lícitos, en atención a la ley de protección de datos personales y se permita la consulta a los usuarios de las personas a las cuales se cedieron o vendieron dichos datos personales para que puedan ejercer sus respectivos derechos legales.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Demás finalidades indicadas en la presente Política, en la autorización otorgada por el titular y/o en los avisos de privacidad.
                </Typography>
              </li>
            </ul>
          </span>
          <Typography color="primary" gutterBottom>
            Finalidades para el tratamiento de datos personales de accionistas
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Convocatoria y ejecución de asambleas, levantamiento y registro de actas y de accionistas.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Envío de información relacionada con las actividades propias del responsable del tratamiento.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Garantizar el efectivo ejercicio de los derechos de los accionistas.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Pago de dividendos o utilidades.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Capitalizaciones, fusiones y/o escisiones.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Contratación con terceros de servicios que beneficien a los accionistas.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Invitación a eventos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Publicación de la calidad de accionista, incluyendo, pero sin limitarse a formatos de vinculación con clientes, aseguradoras, entidades financieras y/o contratistas y/o proveedores, entre otros.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Uso de información para publicidad y medios de comunicación.
                </Typography>
              </li>
            </ul>
          </span>
          <Typography color="primary" gutterBottom>
            Finalidades para el tratamiento de datos personales de aspirantes, empleados directos e indirectos, practicantes, aprendices y beneficiarios y familias
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Recolección de hojas de vida directamente del titular o de terceras personas naturales o jurídicas que las remitan, bien sea de manera independiente, o por encargo del responsable del tratamiento.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión y promoción de empleo, desarrollo del proceso de selección, análisis de hojas de vida, validación de referencias laborales y/o personales, entrevistas y certificados de aptitud ocupacional, pruebas psicotécnicas y de competencias que se requieran.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Conservación de hojas de vida y resultados de los procesos de selección para futuros procesos de vinculación de personal y/o por cumplimiento de norma legal vigente.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Vinculación laboral, suscripción de contratos laborales.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Proceso de inducción y reinducción.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Proceso de carnetización.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Control de renovación del contrato.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Control de ingreso y horario.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Programación de trabajo y asignación de funciones, roles y perfiles asociados al cargo desempeñado.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Registro de información de empleados directos e indirectos, activos e inactivos, pensionados y de sus familias, para el desarrollo de las actividades de afiliación y pago de seguridad social y parafiscales, nómina, primas y bonificaciones, vacaciones, reconocimiento de derechos pensionales y liquidaciones.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Actividades de clima, cultura organizacional y bienestar de los empleados directos e indirectos y sus familias.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión de permisos, licencias y autorizaciones.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Procesos disciplinarios, gestión de sanciones, amonestaciones, llamadas de atención, descargos y despidos con o sin justa causa.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Registro del historial disciplinario.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Cumplimiento de obligaciones del responsable del tratamiento, en virtud de las normas legales vigentes.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Capacitación y formación al personal directo e indirecto.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Evaluaciones de competencias y desempeño.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Descuentos al salario permitidos en normas vigentes y práctica y registro de embargos por requerimiento de autoridad competente.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Entrega de dotación, equipos y activos fijos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Revisión de documentos para retiro de cesantías.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Contratación con terceros de servicios que beneficien a los empleados directos e indirectos y sus familias.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Cumplimiento de las normas vigentes en temas de salud y seguridad en el trabajo SG-SST.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Suministro de información a contratistas y proveedores, para la ejecución de los contratos suscritos entre estos y el responsable del tratamiento.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Creación y control de acceso y modificación de documentos almacenados en carpetas compartidas.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Reservas hoteleras, tiquetes aéreos o terrestres, entrega de vales de gasolina y peajes, viáticos y solicitud de vehículos, entre otros.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Trámite de incapacidades o licencias ante las entidades que corresponda.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Identificación y seguimiento de ingresos y egresos de personal, nómina y ascensos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Publicidad propia y/o de aliados estratégicos en cualquier medio de comunicación, así como publicación de contenidos editoriales, blogs o informes de gestión, entre otros, que contengan información personal del titular incluyendo fotografías y/o videos en la que aparezca el mismo, con la indicación del cargo que desempeña y su nombre.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Transferencia de comprobante de pago de aportes al sistema de seguridad social y parafiscales y constancia de capacitaciones realizadas a los colaboradores, remitida a los contratantes del responsable del tratamiento, cuando se requiera para el pago de bienes y/o servicios prestados por este último en calidad de contratista y/o proveedor y/o para el ingreso a instalaciones de terceros.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Publicación de la calidad de empleado por cualquier medio de comunicación, incluyendo, pero sin limitarse a contratos, formatos de vinculación con clientes, entidades financieras y/o contratistas y/o proveedores, correo electrónico, entre otros.
                </Typography>
              </li>
            </ul>
          </span>
          <Typography color="primary" gutterBottom>
            Finalidades para el tratamiento de datos personales de clientes, distribuidores y usuarios activos e inactivos, prospectos y sus colaboradores
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Información de empleados del cliente, usuario y/o distribuidor.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Análisis de comportamiento, perfiles y segmentación del mercado.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Prospección comercial con apoyo en la revisión de información pública disponible en redes sociales.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Personalizar la experiencia del titular, respondiendo mejor a sus necesidades individuales.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Mejorar nuestro Sitio Web y/o App y sus funcionalidades.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Ofrecimiento de bienes y/o servicios del responsable del tratamiento y/o de sus aliados estratégicos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Transferencia de datos personales a los proveedores, operadores logísticos, transportadores, empresas de pagos, entidades financieras, aseguradoras, subsidiarias, controladoras y filiales, entre otros, que requieran conocer dicha información para proporcionar los servicios de forma efectiva.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Seguimiento y rastreo de las operaciones de recolección y/o entrega de productos, incluyendo, pero sin limitarse a: uso de tecnologías de trazabilidad y rastreo, bienes comprados o recolectados, monto pagado, fecha de compra o recolección, hora de entrega o recolección, entre otros datos relacionados con la compra y entrega de los productos comprados o bienes recolectados para reciclaje.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Estudios estadísticos de comportamiento de riesgo comercial, financiero y crediticio.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Aprobación y renovación de cupo de crédito.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Información patrimonial propia y de socios o accionistas, para respaldo o garantía del pago del crédito.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Consultas y reportes positivos y negativos de información comercial, financiera y crediticia ante operadores de información o administradoras de bases de datos, entidades financieras y crediticias, agencias de información comercial y centrales de riesgo legalmente establecidas.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Suscripción y ejecución de contratos.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión de logística nacional e internacional.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Transporte de mercancía.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Control de inventarios.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Cumplimiento de obligaciones legales y contractuales.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión de facturación.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión de recuperación de cartera mediante el cobro persuasivo, extrajudicial y/o judicial.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Identificación de deudores y/o codeudores.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión de clientes, distribuidores y/o usuarios.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Gestión de redes sociales.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Fidelización de clientes, distribuidores y/o usuarios.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Histórico de relaciones comerciales.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Envío de publicidad, marketing, inteligencia artificial, encuestas de opinión, prospección comercial, venta a distancia y comercio electrónico.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Transmisión y transferencia de datos de contacto a los encargados del tratamiento, contratistas y proveedores, otros clientes y/o aliados estratégicos, anunciantes, patrocinadores de concursos, socios promocionales y de marketing, compañías afiliadas y socios comerciales actuales y futuros, y si estamos involucrados en una fusión, venta de activos u otra reorganización comercial. para que traten los datos personales del titular, para los fines indicados en la presente política de privacidad.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Evaluación de la calidad de los bienes y/o servicios prestados por el responsable del tratamiento.
                </Typography>
              </li>
            </ul>
          </span>
          <Typography color="primary" gutterBottom>
            Finalidades para el tratamiento de datos personales de funcionarios entes gubernamentales
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Coordinación de actividades en el marco de cumplimiento de normas vigentes
                </Typography>
              </li>
            </ul>
          </span>

          <Typography paragraph>
            Los titulares reconocen y aceptan que los datos recolectados serán objeto de análisis para fines de mejorar la estrategia de negocios del Sitio Web, apoyada en herramientas de inteligencia de negocios y mapeo de datos, que permiten adquirir conocimientos prospectivos para fines de predicción, clasificación y segmentación. Con la sola entrega de los datos solicitados por el sitio web, la App o por cualquier otro medio, los usuarios autorizan a Muta para utilizar dichos datos de acuerdo con las finalidades antes señaladas, así como la de ser contactados mediante diferentes vías de acuerdo con los datos proporcionados para la comercialización o publicidad de bienes y servicios propios o ajenos, promociones, descuentos y ofertas, compartir y/o circular dicha información de manera agregada o desagregada a personas naturales o jurídicas que garanticen una protección adecuada de dichos datos para lo cual Muta cuenta con la más amplia facultad y autonomía para determinar a quién ceder dichos datos, lo cual es aceptado expresamente por los usuarios con la aceptación de esta política y, finalmente, para realizar los reportes correspondientes a las centrales de riesgo crediticio cuando sea procedente en virtud de los incumplimientos de los usuarios.
          </Typography>

          <Typography paragraph>
            Los datos que serán recolectados de los usuarios para registrarse en el Sitio Web o la App, serán:
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Nombre y apellido
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Número de teléfono
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Dirección de correo electrónic
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Contraseña
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Dirección física
                </Typography>
              </li>
            </ul>
          </span>
          <Typography paragraph>
            <s>Lo anterior, sin perjuicio de la recolección automática de datos personales a través de cookies, pixeles o tecnologías similares.</s>
          </Typography>

          <Typography paragraph>
            Los titulares podrán ejercer su derecho de conocer, actualizar, modificar y suprimir los datos personales existentes en las bases de datos de Muta, incluyendo con quién ha compartido dichos datos o revocar la autorización que haya otorgado para su tratamiento. Para ello deberá realizar la solicitud de consulta, petición o reclamo al correo electrónico support@mutaworld.com, detallando las consultas, solicitudes o modificaciones a realizar y aportando los documentos que lo soportan. Muta es responsable del tratamiento de la información personal recolectada a través del sitio web, la aplicación móvil o cualquier otro medio que utilice para la recolección de dichos datos, responsabilidad que podrá delegar en un tercero, en calidad de responsable o encargado de la información, asegurando contractualmente el adecuado tratamiento de la misma.
          </Typography>
          <Typography paragraph>
            El área encargada de atender su solicitud, es Tech.
          </Typography>

          <Typography color="primary" gutterBottom>
            Aviso de privacidad – REACEICO S.A.S.
          </Typography>
          <Typography paragraph>
            Para dar cumplimiento a lo dispuesto en la Ley 1266 de 2008, la Ley 1581 de 2012, el Decreto 1377 de 2013 así como las demás normas concordantes al manejo de datos personales, REACEICO S.A.S.., identificada con el NIT 900.703.660 - 3, le informa que sus datos serán tratados conforme a este aviso de privacidad y a la POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES que lo contiene. En este caso Muta actúa como responsable del tratamiento de los datos personales, por lo que mantendrá almacenados estos datos y guardará su confidencialidad atendiendo los requisitos y medidas de seguridad exigidos por la ley.
          </Typography>

          <Typography color="primary" gutterBottom>
            Derechos que le asisten al Usuario
          </Typography>
          <Typography paragraph>
            (i) Conocer, actualizar y rectificar sus datos personales recolectados por Muta. Este derecho se puede ejercer entre otros frente a datos parciales, inexactos, incompletos, fraccionados o que induzcan al error, (ii) Solicitar prueba de la autorización otorgada a Muta (iii) Ser informado por Muta, previa solicitud, respecto del uso que le han dado a los datos del titular, incluyendo los terceros a los cuales se le han cedido o vendido los datos (iv) Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley, (v) Revocar en cualquier tiempo la autorización y/o supresión del dato teniendo en cuenta los límites legales para ello, (vi) Acceder en forma gratuita a los datos del titular contenidos en la base de datos de Muta.
          </Typography>

          <Typography color="primary" gutterBottom>
            Forma de conocer la información del Titular y cómo ejercer los derechos
          </Typography>
          <Typography paragraph>
            Muta presenta a elección del Titular las siguientes formas de ejercer sus derechos de “Habeas Data”:
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Atención electrónica:</b> El Usuario que sea titular del dato deberá realizar su requerimiento formal al correo electrónico support@mutaworld.com previo agotamiento de los requisitos de legitimación para el ejercicio del Usuario, a saber: (i) Ser titular de la información; (ii) Acreditar la identidad en forma suficiente mediante cualquier medio idóneo o por los causahabientes, quienes deberán acreditar tal calidad, o el representante/apoderado del titular de la información, quien también deberá acreditar tal calidad.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Atención escrita:</b> El usuario titular del dato deberá realizar su requerimiento formal a la dirección CRA 53 N 3 05, en Barranquilla, Atlántico, Colombia, previo agotamiento de los requisitos de legitimación para el ejercicio del Usuario titular, según lo descrito anteriormente, mediante escrito donde se especifique la dirección donde recibirá notificaciones y respuestas.
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Las peticiones y reclamos, serán resueltas dentro de los quince (15) días hábiles siguientes a su presentación por parte del titular o persona legitimada. Los términos aquí establecidos, empezarán a contar el día hábil siguiente a la presentación. Cuando no fuere posible atender la solicitud dentro de dicho término, antes de finalizar el mismo se le informará al interesado los motivos de dicha situación y la fecha máxima de respuesta que será dentro de los ocho (8) días hábiles siguientes al vencimiento del primer término.
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Las consultas, deberán resolverse dentro de los diez (10) días hábiles siguientes a su presentación por parte del titular o persona legitimada. Los términos aquí establecidos, empezarán a contar el mismo día de presentación, salvo que se presente en días no hábiles. En este último caso, los términos comenzarán a regir el primer día hábil siguiente a la presentación. Cuando no fuere posible atender la solicitud dentro de dicho término, antes de finalizar el mismo se le informará al interesado los motivos de dicha situación y la fecha máxima de respuesta que será dentro de los cinco (5) días hábiles siguientes al vencimiento del primer término.
                </Typography>
              </li>
            </ul>
          </span>

          <Typography color="primary" gutterBottom>
            Política de cookies, pixeles o tecnologías similares
          </Typography>
          <Typography paragraph>
            El Sitio Web utilizará cookies, pixeles o tecnologías similares de terceros, tales como (Target Marketing, Google Analytics and Facebook Pixel) con el fin de almacenar y recordar los datos personales como nombre, número de documento y contraseña de los Usuarios, tipo de navegador de Internet y dispositivo que utiliza, geolocalización por GPS, dirección IP, idioma, fechas y tiempos de navegación, páginas consultadas, entre otros, para:
          </Typography>
          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Facilitar la posterior navegación y acceso a la red.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Analizar perfiles, tendencias y preferencias comerciales, prospección de necesidades comerciales, comportamiento y segmentación del mercado.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Recopilar información estadística, incluyendo, pero sin limitarse a: navegadores, dispositivos y países desde los que se consulta nuestro sitio web, qué páginas ha visto un usuario o cuánto tiempo ha estado en nuestro sitio web.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  Optimizar los anuncios publicitarios que mostramos en el dispositivo del usuario o en otras páginas web.
                </Typography>
              </li>
            </ul>
          </span>
          <Typography variant="subtitle1" paragraph>
            La mayoría de los navegadores web se pueden configurar para desactivar el uso de cookies o de otras tecnologías. Sin embargo, si desactiva las cookies, es posible que no pueda acceder a la funcionalidad de nuestro Sitio Web correctamente o en absoluto.
          </Typography>

          <span>
            <ul>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Definición de cookies y pixeles de seguimiento</b>
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Las cookies son archivos de texto que se descargan en el dispositivo del Usuario y recogen de manera automática información que puede ser personal y que puede utilizarse en el caso de MUTA, para las finalidades relacionadas en el presente documento.
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Los pixeles de seguimiento, son imágenes gráficas incrustadas en los códigos de las páginas web, correos electrónicos y/o aplicaciones entre otros, que permiten identificar el interés de los Usuarios por ciertas publicaciones, número de vistas, intereses, entre otros.
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1" paragraph>
                  <b>Aceptación del uso de cookies, pixeles y tecnologías similares</b>
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  Con el hecho de aceptar la presente Política de Tratamiento de Datos Personales, el Usuario autoriza el uso de cookies, pixeles y tecnologías similares y como consecuencia, el almacenamiento y tratamiento de sus datos para las finalidades establecidas en esta Política.
                </Typography>
              </li>
            </ul>
          </span>

          <Typography ref={vigencia} color="primary" gutterBottom>
            <span className="secondaryTitle">
              Vigencia
            </span>
          </Typography>
          <Typography variant="subtitle1" paragraph>
            La presente Política rige a partir de 26 de Septiembre del 2023 y tendrá una vigencia igual al tiempo en que se mantenga y utilice la información para las finalidades descritas en esta Política. Una vez se cumplan estas finalidades, salvo que exista un deber legal o contractual de conservar los datos personales, estos serán eliminados de las bases de datos de REACEICO S.A.S.
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Muta se reserva el derecho de modificar en cualquier momento la presente política de privacidad. En caso de haber cambios sustanciales en el contenido de esta, en relación con la identificación del responsable del tratamiento y la finalidad del tratamiento de los datos personales, los cuales puedan afectar el contenido de la autorización, Muta comunicará estos cambios al titular antes de o a más tardar al momento de implementar las nuevas políticas y requerirá una nueva autorización cuando el cambio se refiera a la finalidad del tratamiento
          </Typography>

        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

const DataPolicies = () => {
  return (
    <React.Fragment>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PoliciesCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default DataPolicies;
