import React from "react";
import { useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

import { mutaTheme } from "../../utils/mutaTheme";
import "../../utils/mutaStyles.css";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const drawerWidth = 260;

function LegalCard() {
  //Para la barra lateral con desplazamiento dentro del contenido de la misma página

  const generales = useRef(null);
  const contacto = useRef(null);
  const objeto = useRef(null);
  const delusuariofinal = useRef(null);
  const accesoplataforma = useRef(null);
  const obligaciones = useRef(null);
  const consideracionesfinales = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <ThemeProvider theme={mutaTheme}>
      <Drawer
        anchor="right"
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <Typography> &nbsp; Indice: </Typography>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(generales)}>
                Términos generales
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(contacto)}>
                Licencia
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(objeto)}>
                Objeto
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(delusuariofinal)}>
                Del Usuario Final
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(accesoplataforma)}>
                Acceso a la Plataforma y a la App
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(obligaciones)}>
                Obligaciones
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(consideracionesfinales)}>
                Consideraciones finales
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Card mb={6}>
        <CardContent>
          <Typography color="primary" gutterBottom>
            <span className="mainTitle" style={{ marginLeft: "0" }}>
              Términos y condiciones
            </span>
          </Typography>
          <Typography gutterBottom>
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>
              Actualizado el 2029-09-19
            </span>
          </Typography>

          <Typography color="primary" gutterBottom>
            <span ref={generales} className="secondaryTitle">
              Términos generales
            </span>
          </Typography>
          <Typography paragraph>
            El presente documento establece los términos y condiciones generales (en adelante los "Términos y Condiciones”) que regulan los servicios ofrecidos a través del sitio web https://mutaworld.com/ (en adelante la “Plataforma”) y en la aplicación para móviles (en adelante la “Apps”) de las cuales es titular REACEICO S.A.S., sociedad colombiana, debidamente constituida, identificada con N.I.T. 900.703.660 - 3 (en adelante el “Titular” o “Muta”). Los usuarios que deseen acceder a los servicios que se ofrecen en la Plataforma o la App, podrán hacerlo sujetándose a los presentes Término y Condiciones, así como a las políticas y principios incorporados en el presente documento. La aceptación se entenderá otorgada con el sólo acceso a los servicios de la Plataforma o la App. En todo caso, quien no acepte los presentes Términos y Condiciones, deberá abstenerse de utilizar la Plataforma y la App o de utilizar los servicios que sean ofrecidos.
          </Typography>
          <Typography paragraph>
            Muta es una empresa de tecnología que ofrece servicios de recolección, transporte y aprovechamiento de diferentes tipos de materiales tales como vidrio, plástico, Tetrapak, aceite, metal, papel, aluminio y/o cartón (en adelante los “<u>Materiales</u>”), a través de plataformas digitales.
          </Typography>
          <Typography paragraph>
            El Titular podrá administrar o gestionar la Plataforma o la App de manera directa o a través de un tercero, lo cual no modifica en ningún sentido lo establecido en los presentes Términos y Condiciones.
          </Typography>
          <Typography paragraph>
            El Titular podrá actualizar, modificar o reemplazar unilateralmente parte o la totalidad de los Términos y Condiciones, los cuales serán vinculantes para todos los Usuarios a partir de su publicación, por lo que los Usuarios tienen la responsabilidad de revisarlos periódicamente para estar al tanto de los cambios y actualizaciones de los mismos.
          </Typography>

          <Typography ref={contacto} color="primary" gutterBottom>
            <span className="secondaryTitle">Información de contacto Muta</span>
          </Typography>
          <Typography paragraph>
            Correo electrónico: <u>support@mutaworld.com</u>
          </Typography>
          <Typography paragraph>
            Teléfono: <u>+57 3112464621</u>
          </Typography>
          <Typography paragraph>
            Dirección física de correspondencia: <u>Cra 53#3-05, Galapa, Atlántico</u>
          </Typography>

          <Typography ref={objeto} color="primary" gutterBottom>
            <span className="secondaryTitle">Objeto</span>
          </Typography>
          <Typography paragraph>
            El objeto de los presentes Términos y Condiciones es regular el acceso a los servicios de Muta disponibles para los usuarios de la Plataforma o la App (en adelante los “<u>Usuarios</u>”, “<u>Usuarios Finales</u>” o individualmente el “<u>Usuario Final</u>” o “<u>Usuario</u>”),  los cuales consisten en la recolección, transporte y aprovechamiento de los Materiales de los Usuarios a cambio de una remuneración en dinero equivalente a la cantidad de Materiales entregados y efectivamente recuperados por Muta, la cual podrán encontrar en su billetera virtual dentro de la Plataforma o la App.
          </Typography>
          <Typography paragraph>
            Muta se reserva la facultad de modificar, en cualquier momento y sin previo aviso, la presentación, los contenidos, la funcionalidad, la información, los servicios y la configuración que pudiera estar contenida en la Plataforma o la App y en consecuencia que se puedan llegar a presentar y prestar a los Usuarios Finales.
          </Typography>
          <Typography paragraph>
            El acceso a la Plataforma y la App de los Usuarios tiene carácter libre y gratuito.
          </Typography>

          <Typography ref={delusuariofinal} color="primary" gutterBottom>
            <span className="secondaryTitle">Del Usuario Final</span>
          </Typography>
          <Typography paragraph>
            El acceso o utilización de la Plataforma o de la App, incluyendo el registro en la misma y la solicitud de recolección, confiere la condición de Usuario Final. En virtud de ello, quien acceda a los servicios prestados por Muta quedará sujeto a los presentes Términos y Condiciones, así como a sus ulteriores modificaciones, de conformidad con la legislación aplicable, por lo tanto, se tendrán por aceptados desde el momento en que ingrese a la Plataforma o/y la App. Dada la relevancia anterior, se recomienda a los Usuarios Finales revisar las actualizaciones que se realicen en los presentes Términos y Condiciones cada vez que vayan a acceder a los servicios prestados por Muta.
          </Typography>
          <Typography paragraph>
            La relación existente entre el Usuario y Muta, una vez perfeccionadas las compras, es una de naturaleza comercial, por tratarse de un negocio jurídico de compraventa de Materiales, sin que de ninguna forma esta interacción genere vínculo laboral entre el Usuario y Muta. La App podrá ser utilizada por el Usuario de manera autónoma y podrá dejar de usarla sin consecuencia legal alguna en cualquier momento.
          </Typography>
          <Typography paragraph>
            Le queda prohibido al Usuario Final la utilización de cualquier tipo de software que automatice la interacción o descarga de los contenidos o servicios proporcionados a través de la Plataforma y/o la App, así como cualquier forma de ingeniería inversa o deconstrucción de los sistemas operativos de la Plataforma y/o de la App. Además, el Usuario se compromete a utilizar la información, contenidos o servicios ofrecidos a través de la Plataforma y/o la App de manera lícita, sin controvertir lo dispuesto en los presentes Términos y Condiciones y la ley aplicable, y se abstendrá de realizar cualquier acto que pueda suponer una afectación a los derechos de Muta, de los proveedores, sus clientes o de terceros, o perjudique de algún modo el funcionamiento de la Plataforma o la App.
          </Typography>
          <Typography paragraph>
            El Usuario se compromete a proporcional información licita y veraz en los formularios habilitados en la Plataforma y/o en la App y acepta que Muta solicite o consulte la veracidad de los datos suministrados dentro de la Plataforma o la App, con el fin de comprobar que la identidad y la información suministrada sea real. En todo caso, el Usuario notificará de forma inmediata a Muta acerca de cualquier hecho que permita suponer el uso indebido de la información registrada en dichos formularios, tales como, robo, extravío o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su inmediata cancelación.
          </Typography>

          <Typography ref={accesoplataforma} color="primary" gutterBottom>
            <span className="secondaryTitle">Acceso a la Plataforma y a la App</span>
          </Typography>
          <Typography paragraph>
            <span className="secondaryTitle">Capacidad</span>
          </Typography>
          <Typography paragraph>
            El uso de la Plataforma y de la App lo realiza el Usuario como persona capaz, manifestando que, para obtener los servicios ofrecidos por Muta, cuenta con plena capacidad legal para ser sujeto de derechos y obligaciones, calidades que refrenda al momento de generar cada registro.
          </Typography>
          <Typography paragraph>
            En virtud de las condiciones de capacidad legal establecidas en el Código Civil colombiano y de la validez de la manifestación de voluntad a través de medios electrónicos establecida en la Ley 527 de 1999, los Usuarios al momento de registrarse en la Plataforma o en la App, manifiestan expresamente tener capacidad para celebrar el tipo de transacciones que se realizarán por medio de la Plataforma y/o la App. Los menores de edad no pueden realizar solicitudes ni registrarse en la Plataforma o la App.
          </Typography>
          <Typography paragraph>
            <span className="secondaryTitle">Exención de responsabilidad.</span>
          </Typography>
          <Typography paragraph>
            El Titular no garantiza de ningún modo la continuidad y disponibilidad obstante, el Titular llevará a cabo las acciones que de acuerdo con sus posibilidades le permitan mantener el buen funcionamiento de la Plataforma y la App, sin que esto suponga alguna responsabilidad por parte del mismo.
          </Typography>
          <Typography paragraph>
            De igual forma, el Titular no será responsable ni garantizará que el contenido del software al que pueda accederse a través de la Plataforma o la App, se encuentren libre de errores, software malicioso o que puedan causar algún daño a nivel de software o hardware en el equipo a través del cual el Usuario accede la Plataforma o la App, por lo que los Usuarios aceptan que acceden a estos bajo su propio riesgo y responsabilidad.
          </Typography>
          <Typography paragraph>
            El Titular tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso inadecuado de la Plataforma o la App. En ningún caso el Titular será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el acceso o utilización de la Plataforma o la App.
          </Typography>
          <Typography paragraph>
            El contenido publicado en la Plataforma o en la App, por cualquier medio o formato (texto, video, imágenes, etc.) cumple con propósitos meramente enunciativos y no constituyen asesoría o consejo sobre cualquiera de los temas que traten.
          </Typography>
          <Typography paragraph>
            Muta no garantiza la actualidad o veracidad de la información contenida en sitios web, archivos, aplicaciones, y demás contenido de terceros a los que el Usuario sea redirigido desde la Plataforma o App del Titular, así como del contenido aportado por otros Usuarios en los espacios dispuesto para ello. El ingreso y uso de los sitios, archivos, aplicaciones y similares a los que sea redirigido el Usuario desde Muta estarán sujetos a los términos y condiciones de los mismos, sobre los que Muta no tiene responsabilidad alguna. Muta tampoco garantiza que el contenido de sitios web, archivos o aplicaciones de terceros cumpla con la legislación colombiana en materia de protección de datos o que el contenido allí presentado sea legal bajo la normatividad colombiana.
          </Typography>
          <Typography paragraph>
            <span className="secondaryTitle">Descripción del servicio.</span>
          </Typography>
          <Typography paragraph>
            El Titular ofrece a través de la Plataforma o de la App servicios de recolección, transporte y aprovechamiento de los Materiales del Usuario a cambio de un precio equivalente a la cantidad de Materiales recolectados por Muta.
          </Typography>
          <Typography paragraph>
            Para que el Usuario pueda realizar una solicitud de compra de Materiales y obtener el precio correspondiente, el Usuario deberá realizar el procedimiento descrito a continuación:
          </Typography>
          <Typography paragraph>
            <ul>
              <li>
                <b>Registro en la Plataforma y la App</b>
                <ul>
                  <li>El Usuario debe iniciar el proceso de registro en la Plataforma o en la App indicando nombre completo, apellido, número de identificación, número de teléfono, y correo electrónico.</li>
                  <li>El Usuario debe crear una cuenta con usuario y clave personal, la cual será única e intransferible. El usuario acepta mantener la clave bajo estricta confidencialidad y así, acepta que será el único responsable de las operaciones que se realicen en su cuenta. El Usuario que identifique un uso o actividad indebida o sospechosa desde su cuenta, deberá notificar inmediatamente a Muta.</li>
                  <li>Al correo del Usuario llegará un código de verificación el cual debe ser ingresado en la Plataforma o la App para la validación de la existencia del correo electrónico. </li>
                  <li>Una vez verificado el correo, el Usuario podrá ingresar a su cuenta en donde deberá indicar la dirección de residencia o dirección donde van a ser recolectados los Materiales comprados. La dirección deberá incluir el complemento, así como la latitud y longitud de la ubicación. El Usuario autoriza a Muta para tomar mediante el GPS la latitud y la longitud de la ubicación del Usuario. El Usuario podrá ingresar cuantas direcciones quiera en la Plataforma.</li>
                </ul>
                <p>Muta podrá rechazar una solicitud de registro o cancelar un registro en la Plataforma o la App, sin que lo anterior genere derecho a resarcimiento de ningún tipo.</p>
              </li>
              <li>
                <b>Solicitud de Compraventa</b>
                <p>Una vez el Usuario Final tenga su cuenta habilitada y la(s) dirección(es) inscrita(s) en la Plataforma, el Usuario podrá solicitar una compraventa de Materiales de la siguiente manera: </p>
                <ul>
                  <li>Indicar la fecha específica o el rango de fechas en la cual el Usuario requiera la recolección de los Materiales que venderá. </li>
                  <li>Escoger la dirección en la cual requiere que Muta realice la recolección.</li>
                  <li>El horario de recolección es de nueve (9) de la mañana a siete (7) de la noche por lo que Muta podrá recoger los Materiales en cualquier momento durante ese lapso de tiempo. Sin perjuicio de lo anterior, el Usuario podrá solicitar a Muta a través de la Plataforma o la App o contactando directamente a uno de los comerciales un horario especial para la recolección.</li>
                </ul>
                <p>Para la solicitud de compraventa no es necesario indicar el tipo de Materiales que se está entregando, sin embargo, el Usuario debe respetar los mínimos indicados a continuación: </p>
                <ul>
                  <p><li>Mínimo de líquidos por recolección: 30 ML</li></p>
                </ul>
              </li>
              <li>
                <b>Materiales</b>
                <p>Se entiende por Materiales todos aquellos que pueden ser aprovechados por Muta y que se identifican en la Plataforma. Los Materiales que están sujetos a la recolección y aprovechamiento por Muta son vidrio, plástico, tetra pak, aceite, metal, papel, aluminio y cartón, entre otros que podrán anunciarse de tiempo en tiempo en la plataforma. El Usuario no podrá solicitar la recolección de otro material distinto a los indicados en la plataforma.</p>
              </li>
              <li>
                <b>Recolección</b>
                <p>En la fecha y hora indicada por el Usuario Final un recolector vinculado a Muta se desplazará a la dirección indicada en la Plataforma para recoger los Materiales. Una vez recogidos los Materiales, el recolector de Muta los llevará a la bodega en donde Muta se encargará de dividirlos por tipo material y pesar cada uno de ellos. </p>
                <p>El Usuario Final a través de la Plataforma y de la App podrá visualizar el estado de su recolección. Si el Usuario Final es una persona jurídica, en la Plataforma o en la App el Usuario podrá visualizar el peso por material y el valor de la recolección por cada material. Si el Usuario Final es persona natural, la Plataforma mostrará el peso total por todos los Materiales recolectados, así como el precio total de los Materiales recolectados.</p>
                <p>El Usuario enviará a Muta mensualmente una factura o una cuenta de cobro con el precio total de las recolecciones efectuadas durante dicho mes.</p>
              </li>
              <li>
                <b>Billetera Virtual</b>
                <p>Por cada recolección, el Usuario Final recibirá una remuneración en dinero equivalente al precio de los Materiales entregados a Muta por peso. El valor dependerá del tipo de material entregado y del peso total de los Materiales, y se indicarán en la Plataforma.</p>
                <p>Cada Usuario dentro de la Plataforma y de la App tendrá una billetera virtual en donde podrá visualizar su balance por cada una de las recolecciones, así como el balance total de todas las recolecciones que han sido solicitadas de tiempo en tiempo. El balance se visualizará en dinero en efectivo y el Usuario podrá solicitar el retiro del dinero una vez tenga acumulado un total de cincuenta mil pesos colombianos ($50.000), o cuando informe a Muta su intención de desvincularse de la Plataforma.  El retiro del dinero se deberá hacer de la siguiente manera:</p>
                <ul>
                  <li>Una vez el Usuario tenga el monto mínimo indicado anteriormente, se le habilitará un botón que permite hacer el retiro del dinero.</li>
                  <li>Cuando el Usuario accede a ese botón de retiro, deberá indicar el monto que quiere retirar y a que banco desea hacer la transferencia de dinero. En la Plataforma se encuentran habilitados para realizar la transferencia todos los bancos disponibles en Colombia.</li>
                  <li>Una vez se realice la transferencia, se descontará del balance del Usuario el monto retirado.</li>
                  <li>El Usuario tendrá disponible el dinero en la cuenta indicada en un máximo de ocho (8) días calendario.</li>
                </ul>
                <p>En caso de que el Usuario quiera desvincularse de la Plataforma y de los Servicios ofrecidos por Muta, y tenga una cantidad de dinero en su billetera virtual, deberá informar a Muta mediante correo electrónico a la dirección indicada en los presente Términos y Condiciones para que Muta pueda transferir los recursos disponibles, siempre y cuando estén al día con el envío de la factura o de la cuenta de cobro correspondiente.</p>
              </li>
            </ul>
          </Typography>

          <Typography ref={obligaciones} color="primary" gutterBottom>
            <span className="secondaryTitle">Obligaciones</span>
          </Typography>
          <Typography paragraph>
            <ul>
              <li><b>Obligaciones de los Usuarios Finales</b></li>
              <p>Con la aceptación de los presentes Términos y Condiciones los Usuarios Finales se obligan a: (1) Suministrar información veraz y fidedigna al momento de registrarse en la Plataforma o en la App; (2) Mantener actualizada la información personal brindada a Muta; (3) Abstenerse de utilizar los servicios ofrecidos por el Titular para realizar actos contrarios a la moral, la ley, el orden público y buenas costumbres en contra del Titular o terceras personas; (4) Solicitar la recolección únicamente de los Materiales aceptados por Muta; (5) enviar dentro del mes siguiente a la recolección la factura o la cuenta de cobro a Muta (5) Abstenerse de suplantar la identidad de otros Usuarios; y (6) En general todas aquellas conductas necesarias para la ejecución del negocio jurídico.</p>
            </ul>
          </Typography>
          <Typography paragraph>
            <ul>
              <li><b>Obligaciones del Titular</b></li>
              <p>En virtud de los presentes Términos y Condiciones el Titular, se obliga a (1) Suministrar información cierta, fidedigna, suficiente, clara y actualizada respecto de los servicios que ofrece; (2) mantener informado al Usuario a través de la aplicación sobre el estado de su recolección indicando si está pendiente por recolectar, recolectada o pagada; (3) poner a disposición de los Usuarios los Términos y Condiciones de uso de la Plataforma y la App de forma actualizada; (4) utilizar la información y datos personales de los Usuarios para los fines establecidos en los presentes Términos y Condiciones; ; y (5) Utilizar mecanismos de información y validación durante la transacción como ventanas emergentes (Pop Ups) o e-mails, que permitan al Usuario estar informado sobre cualquier novedad presentada;</p>
            </ul>
          </Typography>

          <Typography ref={consideracionesfinales} color="primary" gutterBottom>
            <span className="secondaryTitle">Consideraciones finales</span>
          </Typography>
          <Typography paragraph>
            <ul>
              <li><b>Obligaciones de los Usuarios Finales</b></li>
              <p>En cumplimiento de las disposiciones colombianas sobre mensajes de datos según la Ley 527 de 1999, se comunica que la legislación nacional reconoce validez a los mensajes de datos y por tanto ellos adquieren carácter y entidad probatoria. En consecuencia, entienden los Usuarios Finales, que mediante el cruce de mensajes de datos los intervinientes pueden dar lugar al nacimiento, modificación y extinción de obligaciones, siendo de su resorte exclusivo el contenido, consecuencias, responsabilidades y efectos de la información generada.</p>
              <li><b>Naturaleza</b></li>
              <p>La relación entre los Usuarios y Muta, que se rige por los Términos y Condiciones establecidos en el presente, es de carácter comercial por tratarse de un negocio de compraventa de Materiales. En consecuencia, no implica ningún tipo de subordinación laboral entre las partes. Por lo tanto, se deja expresa constancia que la presente relación jurídica no genera vínculo subordinado alguno entre el Usuario y Muta.</p>
              <li><b>Legislación aplicable</b></li>
              <p>Los presentes Términos y Condiciones se regirán en todos sus efectos por su contenido y por la ley colombiana aplicable.</p>
              <li><b>Aceptación total de los términos</b></li>
              <p>El Usuario manifiesta expresamente que ha leído, que entiende y que acepta la totalidad de los Términos y Condiciones, por lo que se compromete al cumplimiento total de los deberes, obligaciones y cargas aquí estipuladas.</p>
              <li><b>Vigencia</b></li>
              <p>Los presentes Términos y Condiciones están vigentes a partir de 2023-09-19 y hasta tanto no sean modificados y la nueva versión publicada en la Plataforma y la App.</p>
            </ul>
          </Typography>

        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

const TermsConditions = () => {
  return (
    <React.Fragment>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <LegalCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default TermsConditions;
