// Layout
import MutaLayout from "./layouts/MutaLayout";
import DataPolicies from "./pages/pages/DataPolicies";
import Home from "./pages/pages/Home";
import PrivacyPolicies from "./pages/pages/PrivacyPolicies";
import TermsConditions from "./pages/pages/TermsConditions";

const routes = [
  //Rutas Propias

  {
    path: "/",
    element: <MutaLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "terminos-y-condiciones",
        element: <TermsConditions />,
      },
      {
        path: "politicas-de-privacidad",
        element: <PrivacyPolicies />,
      },
      {
        path: "politicas-de-tratamiento-de-datos",
        element: <DataPolicies />,
      },
    ],
  },
];

export default routes;
