import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  CardContent,
  Grid,
  Link,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";

import { mutaTheme } from "../../utils/mutaTheme";
import "../../utils/mutaStyles.css";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const legalInfo = [
  { name: "Términos y Condiciones ", route: "terminos-y-condiciones" },
  { name: "Políticas de Privacidad", route: "politicas-de-privacidad" },
  {
    name: "Política de Tratamiento de Datos Personales",
    route: "politicas-de-tratamiento-de-datos",
  },
];

function HomeCard() {
  return (
    <ThemeProvider theme={mutaTheme}>
      <Card mb={6}>
        <CardContent>
          <Typography color="primary" gutterBottom>
            <span className="mainTitle">Información legal</span>
          </Typography>
          {legalInfo.map((item, index) => {
            return (
              <Typography
                key={index}
                variant="body2"
                className="optionBox"
                gutterBottom
              >
                <Link
                  component={NavLink}
                  to={item.route}
                  color="secondary"
                  className="secondaryTitle"
                  style={{ textDecoration: "none" }}
                >
                  {item.name}
                </Link>
              </Typography>
            );
          })}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

const Home = () => {
  return (
    <React.Fragment>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <HomeCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default Home;
