import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import Settings from "../components/Settings";
import GlobalStyle from "../components/GlobalStyle";
import MutaBar from "../pages/presentation/muta/MutaBar";

const Root = styled.div`
  max-width: 754px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: White;
`;

const MutaLayout = ({ children }) => {
  return (
    <Root>
      <MutaBar />
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default MutaLayout;
