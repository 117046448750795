import React from "react";
import { useRef } from "react";
import styled from "@emotion/styled";

import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

import { mutaTheme } from "../../utils/mutaTheme";
import "../../utils/mutaStyles.css";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)(spacing);

const drawerWidth = 260;

function PoliciesCard() {
  //Para la barra lateral con desplazamiento dentro del contenido de la misma página

  const definicionesClaves = useRef(null);
  const recopilar = useRef(null);
  const usoInfo = useRef(null);
  const finalTerceros = useRef(null);
  const clienteTerceros = useRef(null);
  const compartirInfo = useRef(null);
  const dondeInfo = useRef(null);
  const usoCorreo = useRef(null);
  const tiempoInfo = useRef(null);
  const proteccionInfo = useRef(null);
  const otrosPaises = useRef(null);
  const seguridad = useRef(null);
  const actualizarInfo = useRef(null);
  const personal = useRef(null);
  const ventaNegocio = useRef(null);
  const afiliados = useRef(null);
  const ley = useRef(null);
  const consentimiento = useRef(null);
  const enlaces = useRef(null);
  const cookies = useRef(null);
  const bloqueo = useRef(null);
  const remarketing = useRef(null);
  const niños = useRef(null);
  const cambiosPolitica = useRef(null);
  const serviciosTerceros = useRef(null);
  const facebookPixel = useRef(null);
  const seguimiento = useRef(null);
  const contacto = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <ThemeProvider theme={mutaTheme}>
      <Drawer
        anchor="right"
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <Typography> &nbsp; Indice: </Typography>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => scrollToSection(definicionesClaves)}
              >
                Definiciones y Términos Clave
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(recopilar)}>
                ¿Qué información recopilamos?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(usoInfo)}>
                ¿Cómo usamos la información que recopilamos?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(finalTerceros)}>
                ¿Cuándo usa MUTA World la información del usuario final de
                terceros?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(clienteTerceros)}>
                ¿Cuándo usa MUTA World la información del cliente de terceros?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(compartirInfo)}>
                ¿Compartimos la información que recopilamos con terceros?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(dondeInfo)}>
                ¿Dónde y cuándo se recopila la información de los clientes y
                usuarios finales?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(usoCorreo)}>
                ¿Cómo utilizamos su dirección de correo electrónico?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(tiempoInfo)}>
                ¿Cuánto tiempo conservamos su información?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(proteccionInfo)}>
                ¿Cómo protegemos su información?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(otrosPaises)}>
                ¿Podría transferirse mi información a otros países?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(seguridad)}>
                ¿La información recopilada a través del Servicio MUTA World es
                segura?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(actualizarInfo)}>
                ¿Puedo actualizar o corregir mi información?
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(personal)}>
                Personal
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(ventaNegocio)}>
                Venta de Negocio
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(afiliados)}>
                Afiliados
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(ley)}>
                Ley que Rige
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(consentimiento)}>
                Tu consentimiento
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(enlaces)}>
                Enlaces a otros Sitios Web
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(cookies)}>
                Cookies
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(bloqueo)}>
                Bloquear y deshabilitar Cookies y tecnologías similares
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(remarketing)}>
                Servicios de Remarketing
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(niños)}>
                Privacidad de los Niños
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(cambiosPolitica)}>
                Cambios en nuestra Política de Privacidad
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => scrollToSection(serviciosTerceros)}
              >
                Servicios de terceros
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(facebookPixel)}>
                Facebook Pixel
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(seguimiento)}>
                Tecnologías de Seguimiento
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection(contacto)}>
                Contáctenos
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Card mb={6}>
        <CardContent>
          <Typography color="primary" gutterBottom>
            <span className="mainTitle" style={{ marginLeft: "0" }}>
              Políticas de privacidad
            </span>
          </Typography>
          <Typography gutterBottom>
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>
              Actualizado el 2022-01-01
            </span>
          </Typography>
          <Typography paragraph>
            MUTA World ("nosotros", "nuestro" o "nos") se compromete a proteger
            su privacidad. Esta Política de privacidad explica cómo MUTA World
            recopila, usa y divulga su información personal. Esta Política de
            privacidad se aplica a nuestro sitio web, https://www.mutaworld.com/
            y sus subdominios asociados (colectivamente, nuestro "Servicio")
            junto con nuestra aplicación, MUTA World. Al acceder o utilizar
            nuestro Servicio, usted indica que ha leído, comprendido y aceptado
            nuestra recopilación, almacenamiento, uso y divulgación de su
            información personal como se describe en esta Política de privacidad
            y en nuestros Términos de servicio.
          </Typography>
          <Typography ref={definicionesClaves} color="primary" gutterBottom>
            <span className="secondaryTitle">
              Definiciones y Términos Clave
            </span>
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Para ayudar a explicar las cosas de la manera más clara posible en
            esta Política de privacidad, cada vez que se hace referencia a
            cualquiera de estos términos, se definen estrictamente como:
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle1" paragraph>
                Cookie: pequeña cantidad de datos generados por un sitio web y
                guardados por su navegador web. Se utiliza para identificar su
                navegador, proporcionar análisis, recordar información sobre
                usted, como su preferencia de idioma o información de inicio de
                sesión.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Compañía: cuando esta política menciona "Compañía", "nosotros",
                "nos" o "nuestro", se refiere a REACEICO SAS (CRA 53 N 3 05,
                GALAPA, ATLÁNTICO, 082001), que es responsable de su información
                en virtud de esta Política de privacidad.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Plataforma: sitio web de Internet, aplicación web o aplicación
                digital de cara al público de MUTA World.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                País: donde se encuentra MUTA World o los propietarios /
                fundadores de MUTA World, en este caso es Colombia
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Cliente: se refiere a la empresa, organización o persona que se
                registra para utilizar el Servicio MUTA World para gestionar las
                relaciones con sus consumidores o usuarios del servicio.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Dispositivo: cualquier dispositivo conectado a Internet, como un
                teléfono, tablet, computadora o cualquier otro dispositivo que
                se pueda usar para visitar MUTA World y usar los servicios.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Dirección IP: a cada dispositivo conectado a Internet se le
                asigna un número conocido como dirección de protocolo de
                Internet (IP). Estos números generalmente se asignan en bloques
                geográficos. A menudo, se puede utilizar una dirección IP para
                identificar la ubicación desde la que un dispositivo se conecta
                a Internet.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Personal: se refiere a aquellas personas que son empleadas por
                MUTA World o están bajo contrato para realizar un servicio en
                nombre de una de las partes.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Datos personales: cualquier información que directa,
                indirectamente o en conexión con otra información, incluido un
                número de identificación personal, permita la identificación de
                una persona física.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Servicio: se refiere al servicio brindado por MUTA World como se
                describe en los términos relativos (si están disponibles) y en
                esta plataforma.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Terceros: se refiere a anunciantes, patrocinadores de concursos,
                socios promocionales y de marketing, y otros que brindan nuestro
                contenido o cuyos productos o servicios que creemos que pueden
                interesarle.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Sitio web: el sitio de MUTA World, al que se puede acceder a
                través de esta URL: https://www.mutaworld.com/
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Usted: una persona o entidad que está registrada con MUTA World
                para utilizar los Servicios.
              </Typography>
            </li>
          </ul>

          <Typography ref={recopilar} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Qué información recopilamos?
            </span>
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Recopilamos información suya cuando visita nuestra plataforma, se
            registra en nuestro sitio, realiza un pedido, se suscribe a nuestro
            boletín, responde a una encuesta o completa un formulario.
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle1" paragraph>
                Nombre / nombre de usuario
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Números de teléfono
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Correos electrónicos
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Contraseña
              </Typography>
            </li>
            También recopilamos información de dispositivos móviles para una
            mejor experiencia de usuario, aunque estas funciones son
            completamente opcionales:
            <li>
              <Typography variant="subtitle1" paragraph>
                Ubicación (GPS): los datos de ubicación ayudan a crear una
                representación precisa de sus intereses, y esto se puede
                utilizar para llevar anuncios más específicos y relevantes a los
                clientes potenciales.
              </Typography>
            </li>
          </ul>

          <Typography ref={usoInfo} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Cómo usamos la información que recopilamos?
            </span>
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Cualquiera de la información que recopilamos de usted puede usarse
            de una de las siguientes maneras:
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle1" paragraph>
                {" "}
                Para personalizar su experiencia (su información nos ayuda a
                responder mejor a sus necesidades individuales)
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Para mejorar nuestra plataforma (nos esforzamos continuamente
                por mejorar lo que ofrece nuestra plataforma en función de la
                información y los comentarios que recibimos de usted)
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Para mejorar el servicio al cliente (su información nos ayuda a
                responder de manera más efectiva a sus solicitudes de servicio
                al cliente y necesidades de soporte)
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Para procesar transacciones
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Para administrar un concurso, promoción, encuesta u otra
                característica del sitio
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                Para enviar correos electrónicos periódicos
              </Typography>
            </li>
          </ul>

          <Typography ref={finalTerceros} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Cuándo usa MUTA World la información del usuario final de
              terceros?
            </span>
          </Typography>
          <Typography paragraph>
            MUTA World recopilará los datos del usuario final necesarios para
            proporcionar los servicios de MUTA World a nuestros clientes. Los
            usuarios finales pueden proporcionarnos voluntariamente la
            información que han puesto a disposición en los sitios web de las
            redes sociales. Si nos proporciona dicha información, podemos
            recopilar información disponible públicamente de los sitios web de
            redes sociales que ha indicado. Puede controlar la cantidad de
            información que los sitios web de redes sociales hacen pública
            visitando estos sitios web y cambiando su configuración de
            privacidad.
          </Typography>
          <Typography ref={clienteTerceros} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Cuándo usa MUTA World la información del cliente de terceros?
            </span>
          </Typography>
          <Typography paragraph>
            Recibimos información de terceros cuando se comunica con nosotros.
            Por ejemplo, cuando nos envía su dirección de correo electrónico
            para mostrar interés en convertirse en cliente de MUTA World,
            recibimos información de un tercero que brinda servicios automáticos
            de detección de fraude a MUTA World. Ocasionalmente, también
            recopilamos información que se pone a disposición del público en los
            sitios web de redes sociales. Puede controlar la cantidad de
            información que los sitios web de redes sociales hacen pública
            visitando estos sitios web y cambiando su configuración de
            privacidad.
          </Typography>
          <Typography ref={compartirInfo} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Compartimos la información que recopilamos con terceros?
            </span>
          </Typography>
          <Typography paragraph>
            Podemos compartir la información que recopilamos, tanto personal
            como no personal, con terceros como anunciantes, patrocinadores de
            concursos, socios promocionales y de marketing, y otros que
            proporcionan nuestro contenido o cuyos productos o servicios creemos
            que pueden interesarle. También podemos compartirlo con nuestras
            compañías afiliadas y socios comerciales actuales y futuros, y si
            estamos involucrados en una fusión, venta de activos u otra
            reorganización comercial, también podemos compartir o transferir su
            información personal y no personal a nuestros sucesores en interés.
            Podemos contratar proveedores de servicios de terceros de confianza
            para que realicen funciones y nos brinden servicios, como el
            alojamiento y el mantenimiento de nuestros servidores y la
            plataforma, almacenamiento y administración de bases de datos,
            administración de correo electrónico, marketing de almacenamiento,
            procesamiento de tarjetas de crédito, servicio y cumplimiento de
            pedidos de productos y servicios que puede comprar a través de la
            plataforma. Es probable que compartamos su información personal, y
            posiblemente alguna información no personal, con estos terceros para
            permitirles realizar estos servicios para nosotros y para usted.
            Podemos compartir partes de los datos de nuestro archivo de
            registro, incluidas las direcciones IP, con fines analíticos con
            terceros, como socios de análisis web, desarrolladores de
            aplicaciones y redes publicitarias. Si se comparte su dirección IP,
            se puede utilizar para estimar la ubicación general y otros datos
            tecnológicos, como la velocidad de conexión, si ha visitado la
            plataforma en una ubicación compartida y el tipo de dispositivo
            utilizado para visitar la plataforma. Pueden agregar información
            sobre nuestra publicidad y lo que ve en la plataforma y luego
            proporcionar auditorías, investigaciones e informes para nosotros y
            nuestros anunciantes. También podemos divulgar información personal
            y no personal sobre usted al gobierno, a funcionarios encargados de
            hacer cumplir la ley o a terceros privados, según consideremos, a
            nuestro exclusivo criterio, necesario o apropiado para responder a
            reclamos, procesos legales (incluidas citaciones), para proteger
            nuestra derechos e intereses o los de un tercero, la seguridad del
            público o de cualquier persona, para prevenir o detener cualquier
            actividad ilegal, poco ética o legalmente procesable, o para cumplir
            con las órdenes judiciales, leyes, reglas y regulaciones aplicables.
          </Typography>
          <Typography ref={dondeInfo} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Dónde y cuándo se recopila la información de los clientes y
              usuarios finales?
            </span>
          </Typography>
          <Typography paragraph>
            MUTA World recopilará la información personal que nos envíe. También
            podemos recibir información personal sobre usted de terceros como se
            describe anteriormente.
          </Typography>
          <Typography ref={usoCorreo} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Cómo utilizamos su dirección de correo electrónico?
            </span>
          </Typography>
          <Typography paragraph>
            Al enviar su dirección de correo electrónico en esta plataforma,
            acepta recibir nuestros correos electrónicos. Puede cancelar su
            participación en cualquiera de estas listas de correo electrónico en
            cualquier momento haciendo clic en el enlace de exclusión voluntaria
            u otra opción para cancelar la suscripción que se incluye en el
            correo electrónico respectivo. Solo enviamos correos electrónicos a
            personas que nos han autorizado a contactarlos, ya sea directamente
            o a través de un tercero. No enviamos correos electrónicos
            comerciales no solicitados, porque odiamos el spam tanto como usted.
            Al enviar su dirección de correo electrónico, también acepta
            permitirnos usar su dirección de correo electrónico para la
            orientación de la audiencia del cliente en sitios como Facebook,
            donde mostramos publicidad personalizada a personas específicas que
            han optado por recibir nuestras comunicaciones. Las direcciones de
            correo electrónico enviadas solo a través de la página de
            procesamiento de pedidos se utilizarán con el único propósito de
            enviarle información y actualizaciones relacionadas con su pedido.
            Sin embargo, si nos ha proporcionado el mismo correo electrónico a
            través de otro método, podemos usarlo para cualquiera de los fines
            establecidos en esta Política. Nota: Si en algún momento desea
            cancelar la suscripción para no recibir correos electrónicos
            futuros, incluimos instrucciones detalladas para cancelar la
            suscripción en la parte inferior de cada correo electrónico.
          </Typography>
          <Typography ref={tiempoInfo} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Cuánto tiempo conservamos su información?
            </span>
          </Typography>
          <Typography paragraph>
            Conservamos su información solo mientras la necesitemos para
            proporcionarle MUTA World y cumplir con los propósitos descritos en
            esta política. Este también es el caso de cualquier persona con la
            que compartamos su información y que lleve a cabo servicios en
            nuestro nombre. Cuando ya no necesitemos usar su información y no
            sea necesario que la conservemos para cumplir con nuestras
            obligaciones legales o reglamentarias, la eliminaremos de nuestros
            sistemas o la despersonalizaremos para que no podamos identificarlo.
          </Typography>
          <Typography ref={proteccionInfo} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Cómo protegemos su información?
            </span>
          </Typography>
          <Typography paragraph>
            Implementamos una variedad de medidas de seguridad para mantener la
            seguridad de su información personal cuando realiza un pedido,
            ingresa, envía o accede a su información personal. Ofrecemos el uso
            de un servidor seguro. Toda la información confidencial / crediticia
            suministrada se transmite a través de la tecnología Secure Socket
            Layer (SSL) y luego se encripta en nuestra base de datos de
            proveedores de pasarela de pago solo para que sea accesible por
            aquellos autorizados con derechos especiales de acceso a dichos
            sistemas, y deben mantener la información confidencial. Después de
            una transacción, su información privada (tarjetas de crédito,
            números de seguro social, finanzas, etc.) nunca se archiva. Sin
            embargo, no podemos garantizar la seguridad absoluta de la
            información que transmita a MUTA World ni garantizar que su
            información en el servicio no sea accedida, divulgada, alterada o
            destruida por una infracción de cualquiera de nuestras condiciones
            físicas, salvaguardias técnicas o de gestión.
          </Typography>
          <Typography ref={otrosPaises} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Podría transferirse mi información a otros países?
            </span>
          </Typography>
          <Typography paragraph>
            MUTA World está incorporada en Colombia. La información recopilada a
            través de nuestro sitio web, a través de interacciones directas con
            usted o del uso de nuestros servicios de ayuda puede transferirse de
            vez en cuando a nuestras oficinas o personal, o a terceros, ubicados
            en todo el mundo, y puede verse y alojarse en cualquier lugar de el
            mundo, incluidos los países que pueden no tener leyes de aplicación
            general que regulen el uso y la transferencia de dichos datos. En la
            mayor medida permitida por la ley aplicable, al utilizar cualquiera
            de los anteriores, usted acepta voluntariamente la transferencia
            transfronteriza y el alojamiento de dicha información.
          </Typography>
          <Typography ref={seguridad} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿La información recopilada a través del Servicio MUTA World es
              segura?
            </span>
          </Typography>
          <Typography paragraph>
            Tomamos precauciones para proteger la seguridad de su información.
            Contamos con procedimientos físicos, electrónicos y administrativos
            para ayudar a salvaguardar, prevenir el acceso no autorizado,
            mantener la seguridad de los datos y usar correctamente su
            información. Sin embargo, ni las personas ni los sistemas de
            seguridad son infalibles, incluidos los sistemas de cifrado. Además,
            las personas pueden cometer delitos intencionales, cometer errores o
            no seguir las políticas. Por lo tanto, aunque hacemos todos los
            esfuerzos razonables para proteger su información personal, no
            podemos garantizar su seguridad absoluta. Si la ley aplicable impone
            algún deber irrenunciable de proteger su información personal, usted
            acepta que la mala conducta intencional serán los estándares
            utilizados para medir nuestro cumplimiento con ese deber.
          </Typography>
          <Typography ref={actualizarInfo} color="primary" gutterBottom>
            <span className="secondaryTitle">
              ¿Puedo actualizar o corregir mi información?
            </span>
          </Typography>
          <Typography paragraph>
            Los derechos que tienes para solicitar actualizaciones o
            correcciones de la información que recopila MUTA World dependen de
            tu relación con MUTA World. El personal puede actualizar o corregir
            su información según se detalla en nuestras políticas de empleo
            internas de la empresa. Los clientes tienen derecho a solicitar la
            restricción de ciertos usos y divulgaciones de información de
            identificación personal de la siguiente manera. Puede comunicarse
            con nosotros para (1) actualizar o corregir su información de
            identificación personal, (2) cambiar sus preferencias con respecto a
            las comunicaciones y otra información que recibe de nosotros, o (3)
            eliminar la información de identificación personal que se mantiene
            sobre usted en nuestro sistema (sujeto al siguiente párrafo),
            cancelando su cuenta. Dichas actualizaciones, correcciones, cambios
            y eliminaciones no tendrán ningún efecto sobre otra información que
            mantenemos o información que hayamos proporcionado a terceros de
            acuerdo con esta Política de privacidad antes de dicha
            actualización, corrección, cambio o eliminación. Para proteger su
            privacidad y seguridad, podemos tomar medidas razonables (como
            solicitar una contraseña única) para verificar su identidad antes de
            otorgarle acceso a su perfil o hacer correcciones. Usted es
            responsable de mantener en secreto su contraseña única y la
            información de su cuenta en todo momento. Debe tener en cuenta que
            tecnológicamente no es posible eliminar todos y cada uno de los
            registros de la información que nos ha proporcionado de nuestro
            sistema. La necesidad de realizar copias de seguridad de nuestros
            sistemas para proteger la información de pérdidas involuntarias
            significa que puede existir una copia de su información en una forma
            que no se pueda borrar y que será difícil o imposible de localizar
            para nosotros. Inmediatamente después de recibir su solicitud, toda
            la información personal almacenada en las bases de datos que usamos
            activamente y otros medios fácilmente buscables se actualizará,
            corregirá, cambiará o eliminará, según corresponda, tan pronto como
            y en la medida en que sea razonable y técnicamente posible. Si es un
            usuario final y desea actualizar, eliminar o recibir cualquier
            información que tengamos sobre usted, puede hacerlo poniéndose en
            contacto con la organización de la que es cliente.
          </Typography>
          <Typography ref={personal} color="primary" gutterBottom>
            <span className="secondaryTitle">Personal</span>
          </Typography>
          <Typography paragraph>
            Si es un trabajador o solicitante de MUTA World, recopilamos la
            información que nos proporciona voluntariamente. Usamos la
            información recopilada con fines de recursos humanos para
            administrar los beneficios a los trabajadores y seleccionar a los
            solicitantes. Puede comunicarse con nosotros para (1) actualizar o
            corregir su información, (2) cambiar sus preferencias con respecto a
            las comunicaciones y otra información que reciba de nosotros, o (3)
            recibir un registro de la información que tenemos relacionada con
            usted. Dichas actualizaciones, correcciones, cambios y eliminaciones
            no tendrán ningún efecto sobre otra información que mantenemos o
            información que hayamos proporcionado a terceros de acuerdo con esta
            Política de privacidad antes de dicha actualización, corrección,
            cambio o eliminación.
          </Typography>
          <Typography ref={ventaNegocio} color="primary" gutterBottom>
            <span className="secondaryTitle">Venta de Negocio</span>
          </Typography>
          <Typography paragraph>
            Nos reservamos el derecho de transferir información a un tercero en
            el caso de una venta, fusión u otra transferencia de todos o
            sustancialmente todos los activos de MUTA World o cualquiera de sus
            afiliadas corporativas (como se define en este documento), o la
            porción de MUTA World o cualquiera de sus Afiliadas corporativas con
            las que se relaciona el Servicio, o en el caso de que discontinuemos
            nuestro negocio o presentemos una petición o hayamos presentado una
            petición contra nosotros en caso de quiebra, reorganización o
            procedimiento similar, siempre que el el tercero acepte adherirse a
            los términos de esta Política de privacidad.
          </Typography>
          <Typography ref={afiliados} color="primary" gutterBottom>
            <span className="secondaryTitle">Afiliados</span>
          </Typography>
          <Typography paragraph>
            Podemos divulgar información (incluida la información personal)
            sobre usted a nuestros afiliados corporativos. Para los propósitos
            de esta Política de Privacidad, "Afiliado Corporativo" significa
            cualquier persona o entidad que directa o indirectamente controla,
            está controlada por o está bajo control común con MUTA World, ya sea
            por propiedad o de otra manera. Cualquier información relacionada
            con usted que proporcionemos a nuestros afiliados corporativos será
            tratada por dichos afiliados corporativos de acuerdo con los
            términos de esta política de privacidad.
          </Typography>
          <Typography ref={ley} color="primary" gutterBottom>
            <span className="secondaryTitle"> Ley que Rige</span>
          </Typography>
          <Typography paragraph>
            Esta Política de privacidad se rige por las leyes de Colombia sin
            tener en cuenta su disposición sobre conflicto de leyes. Usted
            acepta la jurisdicción exclusiva de los tribunales en relación con
            cualquier acción o disputa que surja entre las partes en virtud de
            esta Política de privacidad o en relación con ella, excepto aquellas
            personas que puedan tener derecho a presentar reclamaciones en
            virtud del Escudo de privacidad o el marco suizo-estadounidense.
            TLas leyes de Colombia, excluyendo sus conflictos de leyes, regirán
            este Acuerdo y su uso de la plataforma. Su uso de la plataforma
            también puede estar sujeto a otras leyes locales, estatales,
            nacionales o internacionales. Al usar MUTA World o comunicarse con
            nosotros directamente, significa que acepta esta Política de
            privacidad. Si no está de acuerdo con esta Política de privacidad,
            no debe interactuar con nuestro sitio web ni utilizar nuestros
            servicios. El uso continuo del sitio web, la interacción directa con
            nosotros o después de la publicación de cambios en esta Política de
            privacidad que no afecten significativamente el uso o divulgación de
            su información personal significará que acepta esos cambios.
          </Typography>
          <Typography ref={consentimiento} color="primary" gutterBottom>
            <span className="secondaryTitle">Tu consentimiento</span>
          </Typography>
          <Typography paragraph>
            Hemos actualizado nuestra Política de privacidad para brindarle
            total transparencia sobre lo que se establece cuando visita nuestro
            sitio y cómo se utiliza. Al utilizar nuestra plataforma, registrar
            una cuenta o realizar una compra, por la presente acepta nuestra
            Política de privacidad y acepta sus términos.
          </Typography>
          <Typography ref={enlaces} olor="primary" gutterBottom>
            <span className="secondaryTitle">Enlaces a otros Sitios Web</span>
          </Typography>
          <Typography paragraph>
            Esta Política de privacidad se aplica solo a los Servicios. Los
            Servicios pueden contener enlaces a otros sitios web que MUTA World
            no opera ni controla. No somos responsables por el contenido, la
            precisión o las opiniones expresadas en dichos sitios web, y dichos
            sitios web no son investigados, monitoreados o verificados por
            nuestra precisión o integridad. Recuerde que cuando utiliza un
            enlace para ir desde los Servicios a otro sitio web, nuestra
            Política de privacidad deja de estar en vigor. Su navegación e
            interacción en cualquier otro sitio web, incluidos aquellos que
            tienen un enlace en nuestra plataforma, están sujetos a las propias
            reglas y políticas de ese sitio web. Dichos terceros pueden utilizar
            sus propias cookies u otros métodos para recopilar información sobre
            usted.
          </Typography>
          <Typography ref={cookies} color="primary" gutterBottom>
            <span className="secondaryTitle">Cookies</span>
          </Typography>
          <Typography paragraph>
            MUTA World utiliza "cookies" para identificar las áreas de nuestro
            sitio web que ha visitado. Una cookie es una pequeña porción de
            datos que su navegador web almacena en su computadora o dispositivo
            móvil. Usamos cookies para mejorar el rendimiento y la funcionalidad
            de nuestra plataforma, pero no son esenciales para su uso. Sin
            embargo, sin estas cookies, es posible que ciertas funciones, como
            los videos, no estén disponibles o se le solicitará que ingrese sus
            datos de inicio de sesión cada vez que visite la plataforma, ya que
            no podríamos recordar que había iniciado sesión anteriormente. La
            mayoría de los navegadores web se pueden configurar para desactivar
            el uso de cookies. Sin embargo, si desactiva las cookies, es posible
            que no pueda acceder a la funcionalidad de nuestro sitio web
            correctamente o en absoluto. Nunca colocamos información de
            identificación personal en cookies.
          </Typography>
          <Typography ref={bloqueo} color="primary" gutterBottom>
            <span className="secondaryTitle">
              Bloquear y deshabilitar Cookies y tecnologías similares
            </span>
          </Typography>
          <Typography paragraph>
            Donde sea que se encuentre, también puede configurar su navegador
            para bloquear cookies y tecnologías similares, pero esta acción
            puede bloquear nuestras cookies esenciales e impedir que nuestro
            sitio web funcione correctamente, y es posible que no pueda utilizar
            todas sus funciones y servicios por completo. También debe tener en
            cuenta que también puede perder información guardada (por ejemplo,
            detalles de inicio de sesión guardados, preferencias del sitio) si
            bloquea las cookies en su navegador. Los distintos navegadores ponen
            a su disposición distintos controles. Deshabilitar una cookie o una
            categoría de cookie no elimina la cookie de su navegador, deberá
            hacerlo usted mismo desde su navegador, debe visitar el menú de
            ayuda de su navegador para obtener más información.
          </Typography>
          <Typography ref={remarketing} color="primary" gutterBottom>
            <span className="secondaryTitle">Servicios de Remarketing</span>
          </Typography>
          <Typography paragraph>
            Usamos servicios de remarketing. ¿Qué es el remarketing? En
            marketing digital, el remarketing (o retargeting) es la práctica de
            publicar anuncios en Internet a personas que ya han visitado su
            sitio web. Permite que su empresa parezca que está "siguiendo" a las
            personas en Internet mediante la publicación de anuncios en los
            sitios web y las plataformas que más utilizan.
          </Typography>
          <Typography ref={niños} color="primary" gutterBottom>
            <span className="secondaryTitle">Privacidad de los Niños</span>
          </Typography>
          <Typography paragraph>
            Recopilamos información de niños menores de 13 años solo para
            mejorar nuestros servicios. Si usted es padre o tutor y sabe que su
            hijo nos ha proporcionado datos personales sin su permiso,
            comuníquese con nosotros. Si nos damos cuenta de que hemos
            recopilado datos personales de cualquier persona menor de 13 años
            sin la verificación del consentimiento de los padres, tomamos
            medidas para eliminar esa información de nuestros servidores.
          </Typography>
          <Typography ref={cambiosPolitica} color="primary" gutterBottom>
            <span className="secondaryTitle">
              Cambios en nuestra Política de Privacidad
            </span>
          </Typography>
          <Typography paragraph>
            Podemos cambiar nuestro Servicio y nuestras políticas, y es posible
            que debamos realizar cambios en esta Política de privacidad para que
            reflejen con precisión nuestro Servicio y nuestras políticas. A
            menos que la ley exija lo contrario, le notificaremos (por ejemplo,
            a través de nuestro Servicio) antes de realizar cambios en esta
            Política de privacidad y le daremos la oportunidad de revisarlos
            antes de que entren en vigencia. Luego, si continúa utilizando el
            Servicio, estará sujeto a la Política de privacidad actualizada. Si
            no desea aceptar esta o cualquier Política de privacidad
            actualizada, puede eliminar su cuenta.
          </Typography>
          <Typography ref={serviciosTerceros} color="primary" gutterBottom>
            <span className="secondaryTitle">Servicios de terceros</span>
          </Typography>
          <Typography paragraph>
            Podemos mostrar, incluir o poner a disposición contenido de terceros
            (incluidos datos, información, aplicaciones y otros servicios de
            productos) o proporcionar enlaces a sitios web o servicios de
            terceros ("Servicios de terceros"). Usted reconoce y acepta que MUTA
            World no será responsable de ningún Servicio de terceros, incluida
            su precisión, integridad, puntualidad, validez, cumplimiento de los
            derechos de autor, legalidad, decencia, calidad o cualquier otro
            aspecto de los mismos. MUTA World no asume ni tendrá ninguna
            obligación o responsabilidad ante usted o cualquier otra persona o
            entidad por los Servicios de terceros. Los Servicios de terceros y
            los enlaces a los mismos se brindan únicamente para su conveniencia
            y usted accede a ellos y los usa completamente bajo su propio riesgo
            y sujeto a los términos y condiciones de dichos terceros.
          </Typography>
          <Typography ref={facebookPixel} color="primary" gutterBottom>
            <span className="secondaryTitle">Facebook Pixel</span>
          </Typography>
          <Typography paragraph>
            Facebook pixel es una herramienta de análisis que le permite medir
            la efectividad de su publicidad al comprender las acciones que las
            personas realizan en su sitio web. Puede utilizar el píxel para:
            Asegurarse de que sus anuncios se muestren a las personas adecuadas.
            Facebook pixel puede recopilar información de su dispositivo cuando
            utiliza el servicio. Facebook pixel recopila información que se
            guarda de acuerdo con su Política de privacidad.
          </Typography>
          <Typography ref={seguimiento} color="primary" gutterBottom>
            <span className="secondaryTitle">Tecnologías de Seguimiento</span>
          </Typography>

          <ul>
            <li>
              <Typography variant="subtitle1" paragraph>
                API de Google Maps
              </Typography>
            </li>
            <Typography variant="subtitle1" paragraph>
              La API de Google Maps es una herramienta sólida que se puede
              utilizar para crear un mapa personalizado, un mapa de búsqueda,
              funciones de registro, mostrar la sincronización de datos en vivo
              con la ubicación, planificar rutas o crear un mashup, solo por
              nombrar algunos. La API de Google Maps puede recopilar información
              suya y de su dispositivo por motivos de seguridad. La API de
              Google Maps recopila información que se mantiene de acuerdo con su
              Política de privacidad.
            </Typography>
            <li>
              <Typography variant="subtitle1" paragraph>
                Cookies
              </Typography>
            </li>
            <Typography variant="subtitle1" paragraph>
              {" "}
              Usamos Cookies para mejorar el rendimiento y la funcionalidad de
              nuestra plataforma, pero no son esenciales para su uso. Sin
              embargo, sin estas cookies, es posible que ciertas funciones, como
              los videos, no estén disponibles o se le solicitará que ingrese
              sus datos de inicio de sesión cada vez que visite la plataforma,
              ya que no podríamos recordar que había iniciado sesión
              anteriormente.
            </Typography>
          </ul>

          <Typography ref={contacto} color="primary" gutterBottom>
            <span className="secondaryTitle">Contáctenos</span>
          </Typography>
          <Typography variant="subtitle1" paragraph>
            No dude en contactarnos si tiene alguna pregunta.
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle1" paragraph>
                A través de correo electrónico: support@mutaworld.com
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" paragraph>
                A través de este enlace: https://www.mutaworld.com/
              </Typography>
            </li>
          </ul>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

const PrivacyPolicies = () => {
  return (
    <React.Fragment>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PoliciesCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default PrivacyPolicies;
